$primary: #505050;

@import "~@verified/veform/themes/default/style.scss";

.main {
	max-width: 1080px !important;
}

div.btn-plus1 {
	background: #003f78!important;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	padding: 6px;
	font-size: 26px;
	line-height: 15px;
	margin: -5px 5px;
}

div.btn-minus1 {
	background: #003f78!important;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	padding: 6px 9px;
	font-size: 26px;
	line-height: 15px;
	margin: -5px 5px;
}

.btn-minus-gray {
	background: #c1c1c1 !important;
	&:hover {
		cursor: not-allowed !important;
	}
}
h5{
	font-size: 0.9rem;
}
h4{
	font-size: 1rem;
}
h3{
	font-size: 1.2rem;
}
.btn-group-toggle > .btn-primary{
	border-radius: 0px;
	font-size: 0.8rem;
	background-color: white !important;
	color: #003f78 !important;
}
.btn-group-toggle > .btn-primary.active {
	background-color: #003f78 !important;
	color: white !important;
}
.nav-link{
	border: 0.5px solid #003f78;
	border-left-width: 0.5px;
	border-radius: 0px !important;
}
.bold{
	font-weight: bold;
}
.form-control{
	font-size: 0.9rem;
}

.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	z-index: 1;
  }
  
  .smooth,
  .smooth * {
	transition: all 0.5s ease;
  
	&.delay,
	& .delay {
	  transition-delay: 0.5s;
	}
  }

.previewContainer {
	position: absolute;
	z-index: 100;
	left: 0;
	top: 0;
	bottom: -2620px;
	width: 100%;
	padding-top: 60px;
	background: rgba(0, 0, 0, 0.91);
	transition: opacity .5s ease-in;
}#myModal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.712);
}

.myModal-content {
  position: relative;
  padding: 0.5rem;
  background-color: #fefefe;
  margin: auto;
  padding: 50px;
  width: 80%;
  max-width: 1200px;
}

.myClose {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.myClose:hover,
.myClose:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}
b{
	font-weight: bold!important;
}