
.flex-columns{display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;-webkit-box-direction:normal;-moz-box-direction:normal;-webkit-box-orient:horizontal;-moz-box-orient:horizontal;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:stretch;-moz-box-pack:stretch;-webkit-justify-content:stretch;-ms-flex-pack:stretch;justify-content:stretch;-webkit-flex-wrap:wrap;-moz-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-flex-wrap:wrap;-moz-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap}.flex-columns .col{flex-grow:1;-webkit-flex-grow:1;-moz-flex-grow:1;-ms-flex-grow:1;background-color:#f3f3f3;padding:8px;border:1px solid #fff;box-sizing:border-box}.flex-columns .col-1{width:100%}.flex-columns .col-2{width:50%}.flex-columns .col-3{width:33.3%}.flex-columns .col-4{width:25%}.flex-columns .col-5{width:20%}.flex-columns .col-6{width:16.6%}.flex-columns .col-7{width:14.28%}.flex-columns .col-8{width:12.5%}.flex-columns .col-9{width:11.1%}.flex-columns .col-10{width:10%}.flex-columns.transparent .col{background-color:transparent;border-color:transparent}table{border-collapse:collapse}table,table td{border:1px solid #eee;background:transparent}table td{padding:8px 12px}table.stripped,table.stripped td{border:0}table.stripped td{border-bottom:1px solid #eee}.flex-columns{margin-left:1px}.flex-columns .col{border:1px solid #eee;background:transparent;padding:8px 12px;margin:-1px 0 0 -1px}.flex-columns.stripped .col{border-left:0;border-right:0}.flex-columns.transparent .col{border:0}
b{font-weight:bold!important;}
input[type="checkbox"]:checked+label:before {
    border-color: #398ECF;
    background-color: #398ECF;
}
.myModal-content, .myModal-content label{
    font-size:12px!important;
}

